<template>
  <div>
    <PageHeader>
      <template slot="title">戻る</template>
      <template slot="content">商品一覧</template>
    </PageHeader>
    <template>案件一覧画面です。</template>
    <template
      >この画面で商品の確認できますし、編集ボタンから編集画面に遷移もできます。</template
    >
    <el-divider></el-divider>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="20" :lg="16" :xl="12">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-input v-model="product_name">
              <template slot="prepend">商品名</template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-input v-model="category1">
              <template slot="prepend">カテゴリー</template>
            </el-input>
          </el-col>
        </el-row>
        <br />
        <el-row :gutter="40">
          <el-col :span="12">
            <el-input v-model="price">
              <template slot="prepend">価格</template>
            </el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <br />
    <el-row :gutter="10">
      <el-col :span="4">
        <el-button
          class="addBtn"
          type="info"
          @click="createBtn()"
          icon="el-icon-document-add"
          plain
          >新 規
        </el-button>
      </el-col>
    </el-row>
    <!--    <el-row :gutter="20">-->
    <!--      <el-col :span="24">-->
    <!--        <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">-->
    <!--          <el-row type="flex" :gutter="40" justify="left">-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item prop="product_name">-->
    <!--                <el-input v-model="ruleForm.product_name" placeholder="商品名">-->
    <!--                  <template slot="prepend">商品名</template>-->
    <!--                </el-input>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item prop="category1">-->
    <!--                <el-input v-model="ruleForm.category1" placeholder="カテゴリー">-->
    <!--                  <template slot="prepend">カテゴリー</template>-->
    <!--                </el-input>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--          </el-row>-->
    <!--          <el-row type="flex" :gutter="40" justify="start">-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item prop="price">-->
    <!--                <el-input v-model="ruleForm.price" placeholder="価格">-->
    <!--                  <template slot="prepend">価格</template>-->
    <!--                </el-input>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--            &lt;!&ndash;            <el-col :span="8">&ndash;&gt;-->
    <!--            &lt;!&ndash;              <el-form-item prop="Is_Delete">&ndash;&gt;-->
    <!--            &lt;!&ndash;                <el-switch&ndash;&gt;-->
    <!--            &lt;!&ndash;                  v-model="ruleForm.Is_Delete"&ndash;&gt;-->
    <!--            &lt;!&ndash;                  active-text="削除済み"&ndash;&gt;-->
    <!--            &lt;!&ndash;                  inactive-text="">&ndash;&gt;-->
    <!--            &lt;!&ndash;                </el-switch>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </el-form-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;            </el-col>&ndash;&gt;-->
    <!--          </el-row>-->
    <!--          <el-row type="flex" :gutter="20" justify="start">-->
    <!--            <el-col :span="11">-->
    <!--              <el-form-item>-->
    <!--                <el-button-group>-->
    <!--                  <el-button type="primary" @click="submitForm('ruleForm')" icon="el-icon-search">検索</el-button>-->
    <!--                  <el-button type="warning" @click="resetForm('ruleForm')">クリア</el-button>-->
    <!--                  <el-button type="success" @click="createBtn">新規</el-button>-->
    <!--                </el-button-group>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--          </el-row>-->
    <!--        </el-form>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
    <el-row :gutter="20">
      <el-col :span="24">
        <el-divider></el-divider>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-table
          :data="
            tableData
              .filter(
                (data) =>
                  !search ||
                  data.product_name.toLowerCase().includes(search.toLowerCase())
              )
              .filter(
                (data) =>
                  !product_name ||
                  data.product_name
                    .toLowerCase()
                    .includes(product_name.toLowerCase())
              )
              .filter(
                (data) =>
                  !category1 ||
                  data.category1.toLowerCase().includes(category1.toLowerCase())
              )
              .filter(
                (data) =>
                  !price ||
                  data.price.toLowerCase().includes(price.toLowerCase())
              )
          "
          v-loading="loading"
          style="width: 100%"
        >
          <template slot="empty">商品がありません</template>
          <el-table-column label="商品名" prop="product_name">
            <template slot-scope="scope">
              <a @click="handleEdit(scope.$index, scope.row)" class="rowTxt">{{
                scope.row.product_name
              }}</a>
            </template>
          </el-table-column>
          <el-table-column label="カテゴリー" prop="category1">
          </el-table-column>
          <el-table-column label="親カテゴリー" prop="category2">
          </el-table-column>
          <!--          <el-table-column-->
          <!--            label="中カテゴリ"-->
          <!--            prop="category1I">-->
          <!--          </el-table-column>-->
          <!--          <el-table-column-->
          <!--            label="小カテゴリ"-->
          <!--            prop="category1II">-->
          <!--          </el-table-column>-->
          <el-table-column label="価格（円）" prop="price" align="right">
            <template slot-scope="scope">
              {{
                scope.row.price.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,")
              }}
            </template>
          </el-table-column>
          <el-table-column align="right">
            <!--eslint-disable-next-line-->
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="search"
                size="mini"
                placeholder="商品名で絞ります"
              />
            </template>
            <!--eslint-disable-next-line-->
            <template slot-scope="scope">
              <!--              <el-button-->
              <!--                size="mini"-->
              <!--                @click="handleEdit(scope.$index, scope.row)">編集-->
              <!--              </el-button>-->
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                plain
                >削除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div>
      <el-dialog
        title="商品登録・更新"
        :visible.sync="dialogFormVisible"
        top="5%"
        v-if="dialogFormVisible"
        :close-on-click-modal="false"
        destroy-on-close
      >
        <ProductForm
          :productId="id"
          @cancel="closeDialog"
          @submit="reloadList"
        ></ProductForm>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  deleteProduct,
  getProductDataAll,
  searchProductData,
} from "api/products";
import PageHeader from "components/common/menu/PageHeader";
import ProductForm from "views/master/ProductForm";
import { Message } from "element-ui";

export default {
  name: "ProductList",
  components: {
    PageHeader,
    ProductForm,
  },
  data() {
    return {
      ruleForm: {
        product_name: "",
        category1: "",
        price: "",
        CreateDate: "",
        Is_Delete: false,
      },
      product_name: "",
      category1: "",
      price: "",
      dialogFormVisible: false,
      loading: false,
      tableData: [],
      search: "",
    };
  },
  created: function () {
    this.getProductDataAll();
  },
  methods: {
    getProductDataAll() {
      this.loading = true;
      getProductDataAll()
        .then((res) => {
          if (res.data.status === 1) {
            this.tableData = res.data.data;
            this.loading = false;
          } else {
            this.tableData = [];
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchProductData() {
      let values = {};
      values["product_name"] = this.ruleForm.product_name;
      values["category1"] = this.ruleForm.category1;
      values["price"] = this.ruleForm.price;
      searchProductData(values)
        .then((res) => {
          this.tableData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.searchProductData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
    reloadList() {
      this.dialogFormVisible = false;
      this.getProductDataAll();
    },
    handleEdit(index, row) {
      this.id = row.id;
      this.dialogFormVisible = true;
    },
    resetForm(formName) {
      console.log("resetForm");
      this.$refs[formName].resetFields();
    },
    handleDelete(index, row) {
      let values = {};
      values["id"] = row.id;
      deleteProduct(values)
        .then((res) => {
          if (res.status === 200) {
            this.getProductDataAll();
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createBtn() {
      this.id = "";
      this.dialogFormVisible = true;
    },
  },
};
</script>

<style scoped>
</style>
